@font-face {
  font-family: alt;
  src: url(./ALT_REKT_SOLID.ttf);
}
.App {
  text-align: center;
}
.mintContainer {
  margin-left: 10px;
}
.gif {
  width: 16vw;
  margin-left: 42vw;
  height: auto;
  margin-bottom: 3vh;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.651) 0px 15px 25px;
}
.logo__image {
  width: 30vw;
  margin-left: 35vw;
  margin-top: 5vh;
  height: auto;
  margin-bottom: 6vh;
}

@media screen and (max-width: 800px) {
  .gif {
    width: 30vw;
    margin-left: 35vw;
    height: auto;
  }
  .logo__image {
    width: 60vw;
    margin-left: 20vw;
    height: auto;
  }
  .mint__quantity {
    font-size: 4vw;
    margin-bottom: 2vh;
  }
}

html,
div {
  background: url(bg.png);
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
}

.MuiButton-containedPrimary,
.MuiButton-root {
  color: #fff;
  font-family: "Gilroy", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
  font-weight: 900 !important;
  background-color: #1e1e1e !important;
  border-radius: 0.5rem !important;
}
.makeStyles-root-1 .MuiDialogTitle-root,
.MuiButton-contained,
.MuiButton-contained {
  background-color: #1e1e1e !important;
  font-family: alt;
  color: white !important;
}
.mint__quantity {
  color: black;
  font-weight: 500;
  font-family: fantasy;
  font-size: 20px;
  margin-left: -80px;
  margin-bottom: 20px;
  min-height: 25px;
}
button.client-sdk-button-trigger.client-sdk-button-trigger-dark {
  margin-top: 45px;
}
.MuiAlert-standardError,
.MuiAlert-root,
.MuiAlert-standardError .MuiAlert-icon,
.MuiAlert-action,
.MuiAlert-message,
.MuiDialogTitle-root {
  background: #383838 !important;
}
